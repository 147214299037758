import { SvgSprite } from '../modules/svg-loader';
import { TnewSYOS } from '../pages/tnew-syos';

(() => {
    // Request SVG sprite
    const spritePath = (window as any).SVG_SPRITE_PATH || '/tnew/Static/img/svg-sprite.svg';
    new SvgSprite(spritePath);

    // TnewSYOS();


    document.addEventListener("DOMContentLoaded", function () {
        // Handler when the DOM is fully loaded

        // check for page headings to apply ID for skip to main content
        let mainHeading = document.querySelector('h1');
        let seasonHeading = document.querySelector('.tn-prod-season-header');

        if (mainHeading) {
            mainHeading.setAttribute('id', 'main-heading');
        } else if (seasonHeading) {
            seasonHeading.setAttribute('id', 'main-heading');
        }
    });
})();
